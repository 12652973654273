import React, { useEffect } from 'react'
import ReactModal from 'react-modal';
import useWindowDimensions from '../../utils/WindowDimensions';
import Button from '../Button';

function Modal({modalVisible, setModalVisible, setQuantidadeConvidadosAdicionaisInput, onConfirm, titulo}: any) {
    const { width } = useWindowDimensions();
    const isMobile = width <= 767;

    const [quantidadeConvidadosAdicionais, setQuantidadeConvidadosAdicionais] = React.useState('0');

    useEffect(() => {
      setQuantidadeConvidadosAdicionaisInput(quantidadeConvidadosAdicionais);
    }, [quantidadeConvidadosAdicionais]);

    return (
      <ReactModal
        isOpen={modalVisible}
        appElement={document.getElementById('root') as HTMLElement}
        contentLabel='Minimal Modal Example'
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setModalVisible(false)}
        style={{
          overlay: {
            opacity: 1,
            backdropFilter: 'blur(2px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 99
          },
          content: {
            display: 'flex',
            flexDirection: 'column',
            height: '30%',
            width: isMobile ? '80%' : '30%',
            margin: 'auto',
            justifyContent: 'space-evenly',
            alignItems: 'center',
          },
        }}
      >
        <b>{titulo}</b>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <label>Convidados Adicionais: </label>
          <input
            type='number'
            value={quantidadeConvidadosAdicionais}
            onChange={(e) => setQuantidadeConvidadosAdicionais(e.target.value)}
            style={{ border: '1px solid #ccc', borderRadius: '4px', padding: '4px', marginLeft: '10px' }}
          />
        </div>
        <Button texto='Confirmar Pedido' onClick={() => onConfirm()} style={{ width: isMobile ? '80%' : '40%' }} />
      </ReactModal>
    )
  }

export default Modal;
